<template>
  <div>
    <v-row class="tw-pb-3">
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="1"
      >
        <statistics-card-horizontal
          :stat-title="totalPatients.statTitle"
          :change="totalPatients.change"
          :color="totalPatients.color"
          :icon="totalPatients.icon"
          :statistics="totalPatientsValue"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="2"
      >
        <statistics-card-horizontal
          :stat-title="totalDrugCost.statTitle"
          :change="totalDrugCost.change"
          :color="totalDrugCost.color"
          :icon="totalDrugCost.icon"
          :statistics="totalDrugCostValue"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
      >
        <statistics-card-horizontal
          :stat-title="averageDaysToCoverage.statTitle"
          :change="averageDaysToCoverage.change"
          :color="averageDaysToCoverage.color"
          :icon="averageDaysToCoverage.icon"
          :statistics="averageDaysToCoverageValue"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="4"
      >
        <statistics-card-horizontal
          :stat-title="averageDaysToSubmission.statTitle"
          :change="averageDaysToSubmission.change"
          :color="averageDaysToSubmission.color"
          :icon="averageDaysToSubmission.icon"
          :statistics="averageDaysToSubmissionValue"
        ></statistics-card-horizontal>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <p color="primary">
          Patient List
        </p>
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="12"
          order="5"
        >
          <v-data-table
            :loading="loading"
            :headers="patientTableHeaders"
            :items="patientListData"
            item-key="group"
            class="table-rounded"
            sort-by="value"
            sort-desc
          >
            <template
              v-for="header in patientTableHeaders.filter(header => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          order="6"
        >
          <total-patients-line-chart
            :height="cardHeight"
          ></total-patients-line-chart>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiStorePlus,
  mdiCashMultiple,
  mdiStore,
  mdiFastForwardOutline,
  mdiSackPercent,
} from '@mdi/js'
import { debounce } from 'lodash'
import { ref, watch } from '@vue/composition-api'
import { patientTableHeaders } from '@/assets/headers/index'
import { sanofiService } from '@/api/index'
import StatisticsCardHorizontal from '@/@core/components/statistics-card/StatisticsCardHorizontal.vue'
import { useFilterStore } from '@/stores/FilterStore'
import { currencyConvert, handleResize, round } from '@/util/functions'
import totalPatientsLineChart from '@/components/charts/totalPatientsLineChart.vue'

export default {
  name: 'PharmacyList',
  components: {
    StatisticsCardHorizontal,
    totalPatientsLineChart,
  },
  setup() {
    const filterStore = useFilterStore()
    const totalPatientsValue = ref('')
    const totalDrugCostValue = ref('')
    const averageDaysToCoverageValue = ref('')
    const averageDaysToSubmissionValue = ref('')

    const totalPatients = {
      statTitle: 'Total Number of Enrolled Patients',
      change: '+',
      icon: mdiCashMultiple,
      color: 'success',
    }
    const totalDrugCost = {
      statTitle: 'Total Drug Cost',
      change: '+',
      icon: mdiStorePlus,
      color: 'primary',
    }

    const averageDaysToCoverage = {
      statTitle: 'Average Days to Coverage',
      change: '+',
      icon: mdiStore,
      color: 'warning',
    }

    const averageDaysToSubmission = {
      statTitle: 'Average Days to Submission',
      change: '+',
      icon: mdiSackPercent,
      color: 'error',
    }

    return {
      totalPatientsValue,
      totalDrugCostValue,
      averageDaysToCoverageValue,
      averageDaysToSubmissionValue,
      filterStore,
      totalPatients,
      totalDrugCost,
      averageDaysToCoverage,
      averageDaysToSubmission,
      handleResize,
    }
  },
  data() {
    return {
      filterStoreUnsubscribe: null,
      patientTableHeaders,
      patientListData: [],
      loading: false,
      cardHeight: 'auto',
      fullHeight: 'auto',
      icons: {
        mdiAccountOutline,
        mdiStorePlus,
        mdiCashMultiple,
        mdiStore,
        mdiFastForwardOutline,
        mdiSackPercent,
      },
    }
  },

  created() {
    window.addEventListener('resize', this.debouncedMatchHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedMatchHeight)
    this.filterStoreUnsubscribe()
  },
  mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    this.getMongoChartData()
    this.matchHeight()
  },
  methods: {
    async matchHeight() {
      this.windowAttributes = await this.handleResize()
      this.cardHeight = ((this.windowAttributes.height - 150) / 2).toString()
      this.fullHeight = (this.windowAttributes.height - 150).toString()
    },
    debouncedMatchHeight: debounce(function () {
      this.matchHeight()
    }, 500),
    async getMongoChartData() {
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,
      }
      const lineChartBody = {
        chartType: 'sanofiLineChartMap',
      }

      const totalPatientsArray = sanofiService.getSanofiData(token, 'totalPatients', body)
      const totalDrugCostArray = sanofiService.getSanofiData(token, 'totalDrugCost', body)
      const patientListDataArray = sanofiService.getSanofiData(token, 'ptTable', body)
      const averageDaysToCoverageArray = sanofiService.getSanofiData(token, 'averageDaysToCoverage', body)
      const averageDaysToSubmissionArray = sanofiService.getSanofiData(token, 'averageDaysToSubmission', body)
      const totalPatientsLineChartArray = sanofiService.getSanofiData(token, 'totalPatientsLineChart', lineChartBody)

      const responses = await Promise.allSettled([
        totalPatientsArray,
        totalDrugCostArray,
        patientListDataArray,
        averageDaysToCoverageArray,
        averageDaysToSubmissionArray,
        totalPatientsLineChartArray,
      ])
      console.log(responses)

      this.totalPatientsValue = responses[0].value[0].value.toString()
      this.totalDrugCostValue = currencyConvert(responses[1].value[0].value).toString()
      this.patientListData = responses[2].value
      this.averageDaysToCoverageValue = round(responses[3].value[0].value).toString()
      this.averageDaysToSubmissionValue = round(responses[4].value[0].value).toString()

      this.loading = false
    },
    gettotalPatients() {
      return this.patientListData.reduce((accumulator, object) => accumulator + object.value, 0)
    },
  },
}
</script>
